<template>
  <div>
    <project-add-new
      :is-add-new-project-sidebar-active.sync="isAddNewProjectSidebarActive"
      :id="id"
      :data="projectData"
      :projectstage-options="projectstageOptions"
      :application-options="applicationOptions"
      :contract-options="contractOptions"
      :document-type-options="documentTypeOptions"
      @refetch-data="refetchData"
      @refetch-statistic="refetchStatistic"
      v-if="ability.can('update', 'projects')"
    >
    </project-add-new>
    <modal-stage
      :project-data="projectData"
      :projectstage-options="projectstageOptions"
      @refetch-data="refetchData"
      @refetch-statistic="refetchStatistic"
    />
    <modal-application
      :project-data="projectData"
      :application-options="applicationOptions"
      @refetch-data="refetchData"
      @refetch-statistic="refetchStatistic"
    />
    <modal-file-manager
      :project_id="project_id"
      :client_code="client_code"
      :open-modal="openModal"
    />
    <project-list-statistic
      :data="statisticOptions"
      :stage-filter.sync="stageFilter"
    />
    <project-list-filter
      :user-filter.sync="userFilter"
      :client-filter.sync="clientFilter"
      :client-options="clientOptions"
      :stage-filter.sync="stageFilter"
      :projectstage-options="projectstageOptions"
    />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜尋..."
              />
              <b-button variant="primary" @click="handleCreate" v-if="false">
                <span class="text-nowrap">Add Project</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refProjectListTable"
        class="position-relative"
        :items="fetchProjects"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <!-- Column: Client Code -->
        <template #cell(client_id)="data">
          <b-link
            :to="{
              name: 'clients-detail',
              params: { id: data.item.client_id },
            }"
            >{{ data.item.client.client_code }}</b-link
          >
        </template>
        <template #cell(file)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            @click="handleUpload(data.item)"
          >
            <feather-icon icon="UploadCloudIcon" />
          </b-button>
        </template>
        <template #cell(contract_id)="data">
           <span v-if="data.item.contract">{{data.item.contract.contract_id}}</span>
        </template>
        <!-- Column: APPLICATION CODE -->
        <template #cell(application_codes)="data">
            <div class="text-capitalize" v-for="item in data.item.application_codes" :key="item.id"
            @click="openApplicationModal(data.item)"
            >
            {{ item.code_name }}
          </div>
        </template>
        <template #cell(application_remark)="data">
           <div v-html="data.item.application_remark"></div>
        </template>

        <template #cell(documents)="data">
          <div v-for="(document, index) in data.item.documents" :key="`document_${index}`">
            <feather-icon :icon="`${document.is_received? 'CheckSquareIcon': 'XSquareIcon'}`" size="18" :class="`mr-50 ${document.is_received? 'text-success': 'text-danger'}`" />{{ document.name }}
          </div>
       </template>

        <!-- Column: PROJECT STAGE -->
        <template #cell(project_stage)="data">
          <b-badge class="text-capitalize"
          v-if="data.item.project_stage"
          @click="openStageModal(data.item)"
          :style="{background:data.item.project_stage.background_color, color: data.item.project_stage.font_color}"
          >
            {{ data.item.project_stage.stage }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="ability.can('update', 'projects')"
              @click="handleEdit(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改項目資料</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="ability.can('update', 'projects')"
              @click="openApplicationModal(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改服務</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="ability.can('update', 'projects')"
              @click="openStageModal(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改狀態</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="ability.can('delete', 'projects')"
              @click="confirmDelete(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">刪除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProjects"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import projectStoreModule from "../projectStoreModule";
import useProjectsList from "./useProjectsList";
import ProjectAddNew from "./ProjectListAddNew.vue";
import Ripple from "vue-ripple-directive";
import ProjectListStatistic from "./ProjectListStatistic.vue";
import ProjectListFilter from "./ProjectListFilter.vue";
import ModalStage from "../modal/ModalStage.vue";
import ModalApplication from "../modal/ModalApplication.vue";
import ModalFileManager from "../modal/ModalFileManager.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ProjectAddNew,
    ProjectListStatistic,
    ProjectListFilter,
    ModalStage,
    ModalApplication,
    ModalFileManager,
  },
  methods: {
    handleUpload(item) {
      this.openModal = !this.openModal;
      if (item.client) {
        this.client_code = item.client.client_code;
      }
      this.project_id = item.project_id;
      this.$bvModal.show("modal-file-manager");
    },
    openStageModal(item) {
      this.projectData = { ...item };
      this.$bvModal.show("modal-stage");
    },
    openApplicationModal(item) {
      this.projectData = { ...item };
      this.$bvModal.show("modal-application");
    },
    getNumberFormat(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HKD",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },
    handleCreate() {
      this.id = 0;
      this.resetProjectData();
      this.isAddNewProjectSidebarActive = true;
    },
    handleEdit(item) {
      this.resetProjectData();
      this.projectData = {...item}
      this.projectData.id = item.id
      this.id = item.id
      this.isAddNewProjectSidebarActive = true;
    },
    resetProjectData() {
      this.projectData = {
        id: 0,
        is_audit: true,
        audit_date: '',
        audit_remark: '',
      };
    },
    confirmDelete(id) {
      this.$swal({
        title: "確認刪除?",
        text: "您的操作是最終的，您將無法檢索該項目。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("project/deleteProject", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
              this.refetchStatistic();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  data() {
    return {
      id: 0,
      projectData: {},
      client_code:'',
      project_id: '',
      openModal: false,
    };
  },
  setup() {
    const PROJECT_STORE_MODULE_NAME = "project";
    const statisticOptions = ref([]);

    if (!store.hasModule(PROJECT_STORE_MODULE_NAME))
      store.registerModule(PROJECT_STORE_MODULE_NAME, projectStoreModule);

    onUnmounted(() => {
      if (store.hasModule(PROJECT_STORE_MODULE_NAME))
        store.unregisterModule(PROJECT_STORE_MODULE_NAME);
    });

    onMounted(() => {
      refetchStatistic()
    })

    const isAddNewProjectSidebarActive = ref(false);

    const refetchStatistic = () => {
      store
        .dispatch(PROJECT_STORE_MODULE_NAME + "/fetchStatistic")
        .then((response) => {
          statisticOptions.value = response.data.projectstages;
          console.log("statisticOptions", statisticOptions.value);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    const applicationOptions = ref([])
    const projectstageOptions = ref([])
    const clientOptions = ref([])
    const contractOptions = ref([])
    const documentTypeOptions = ref([])
    store.dispatch('app/fetchOptionList', {client: true, project_stage: true, application_code: true, contract: true, document_type: true})
    .then((response) => {
      clientOptions.value = response.data.clients;
      projectstageOptions.value = response.data.project_stages;
      applicationOptions.value = response.data.application_codes;
      contractOptions.value = response.data.contracts;
      documentTypeOptions.value = response.data.document_types;
    })
    .catch((error) => {
        console.log(error)
    })
   
    const {
     
      fetchProjects,
      tableColumns,
      perPage,
      currentPage,
      totalProjects,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProjectListTable,
      refetchData,
      ability,
      userFilter,
      clientFilter,
      stageFilter,
    } = useProjectsList();

    return {
      //Sidebar
      isAddNewProjectSidebarActive,
      fetchProjects,
      tableColumns,
      perPage,
      currentPage,
      totalProjects,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProjectListTable,
      refetchData,
      avatarText,
      ability,
      userFilter,
      clientFilter,
      stageFilter,
      statisticOptions,
      refetchStatistic,
      projectstageOptions,
      clientOptions,
      applicationOptions,
      contractOptions,
      documentTypeOptions
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.badge {
  width:100%;
}

.document-selector {
  min-width: 200px;
  max-width: 100%;
}

</style>