<template>
  <b-modal
    id="modal-file-manager"
    ok-title="submit"
    cancel-variant="outline-secondary"
    scrollable
    size="xl"
    title="File Manager"
  >
    <file-manager v-bind:settings="settings" :key="componentKey"></file-manager>
  </b-modal>
</template>

<script>
import {
    BModal, VBModal,
} from "bootstrap-vue"

export default{
    data() {
        return {
            settings: {
                // axios headers
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
                    'client-code': this.client_code,
                },
                //root: process.env.VUE_APP_IMAGE_URL,
                baseUrl: process.env.VUE_APP_LFM_AXIOS_BASE_URL, // overwrite base url Axios
                windowsConfig: 2, // overwrite config
                lang: 'en', // set language
            },
            jump_into_the_file:false,
            jump_into_the_project_file:false,
            componentKey: 0,
        }
    },
    components:{
        BModal,
        VBModal,
    },
    props:{
        client_code:{
            type: String,
            required: true,
        },
        project_id:{
            type: String,
            required: true,
        },
        openModal:{
            type: Boolean
        }
    },
    methods:{
        initialSetting(){
            this.settings = {
                // axios headers
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
                    'client-code': this.client_code,
                },
                //root: process.env.VUE_APP_IMAGE_URL,
                baseUrl: process.env.VUE_APP_LFM_AXIOS_BASE_URL, // overwrite base url Axios
                windowsConfig: 2, // overwrite config
                lang: 'en', // set language
            }
        },
        changeGridView(){
            let button = null
            button = document.querySelector('[title="Grid"]')
            if(button){
                //console.log('Successully changed the gridview')
                button.click() 
            }else{
               // console.log('Fail to change the gridview')
            }
        },
        go_into_file(){
            setTimeout(() => {
                console.log('go_into_file')
                let folder = document.querySelector('[title="'+this.client_code+'"]')
                if(folder != null)
                {
                // Simulate the double click event
                    var clickEvent  = document.createEvent ('MouseEvents');
                    clickEvent.initEvent('dblclick', true, true);
                    folder.dispatchEvent(clickEvent);
                    this.jump_into_the_file = true
                }
            }, 1000)
        },
        go_into_project_folder(){
             setTimeout(() => {
                let folder = document.querySelector('[title="'+this.project_id+'"]')
                if(folder != null)
                {
                // Simulate the double click event
                    console.log('go into project folder')
                    var clickEvent  = document.createEvent ('MouseEvents');
                    clickEvent.initEvent('dblclick', true, true);
                    folder.dispatchEvent(clickEvent);
                    this.jump_into_the_project_file = true
                }
            }, 1000)
        }
    },
    computed:{
        watch_client_code(){
            return this.client_code
        },
        watch_project_id(){
            return this.project_id
        },
        directory() {
            return this.$store.getters['fm/tree/directories'];
        }
    },
    watch:{
        openModal(){
            this.jump_into_the_file = false
            this.jump_into_the_project_file = false
        },
        watch_project_id(){
                this.initialSetting()
        },
        directory() {
            this.changeGridView()
            console.log('directory changed')
            let filter_directory = this.directory.filter(item=>(item.basename == this.client_code || item.basename == this.project_id))
            console.log(filter_directory.length)

            if(filter_directory.length>0 && this.jump_into_the_file && !this.jump_into_the_project_file && this.directory != null){
                this.go_into_project_folder()
            }
            if(filter_directory.length>0 && !this.jump_into_the_file && this.directory != null)
            {
                console.log('this.client_code', this.client_code)
                
                let folder = document.querySelector('[title="'+this.client_code+'"]')
                console.log('folder', folder)
                //this.go_into_file()
                // if(folder != null)
                // {
                // // Simulate the double click event
                //     var clickEvent  = document.createEvent ('MouseEvents');
                //     clickEvent.initEvent('dblclick', true, true);
                //     folder.dispatchEvent(clickEvent);
                // }else{
                //     folder = document.getElementsByClassName('unselectable')
                //     console.log('folder', folder)
                //     if(folder.length > 0)
                //     {
                //         folder[0].click()
                //     }
                
                // }
                // this.jump_into_the_file = true
            }
        }
    },
    mounted() {
        console.log('modal-file-manager changed')
           this.changeGridView()
    },

}

</script>

<style>
#modal-file-manager #modal-file-manager___BV_modal_content_{
    min-height: 90vh !important;
}
</style>
