<template>
    <b-card v-if="data" no-body class="card-statistics">
      <b-card-header v-if="false">
        <b-card-title>Statistics</b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col v-for="item in data" :key="item.stage" xl="3" sm="6">
            <b-media no-body>
              <b-media-body class="my-auto">
       
                <b-badge
                  :style="{
                    background: item.background_color,
                    color: item.font_color,
                  }"
                  class="badge-glow"
                  href="#"
                  @click="changeFilter(item.id)"
                >
                  {{ item.stage }} (<span>{{
                    item.projects_count
                  }}</span
                  >)
                </b-badge>
          
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BBadge,
  } from "bootstrap-vue";
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardTitle,
      BCardText,
      BCardBody,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
      BBadge,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      stageFilter: {
        type: [Number, null],
        default: null,
      },
    },
    methods:{
      changeFilter(id)
      {
        if(this.stageFilter == id)
        {
          this.$emit("update:stageFilter", null);
        }else{
          this.$emit('update:stageFilter', id)
        }
      }
    }
  };
  </script>
  
  <style lang="scss">
  .badge {
    width: 100%;
    margin:10px 0;
    padding:10px;
  }
  </style>